import React from 'react';
import '../../App.css';
import Footer from '../Footer';
import PageTitle from '../PageTitle';

function Contact () {
    const [result, setResult] = React.useState("");
      
    const onSubmit = async (event) => {
      event.preventDefault();
      setResult("Sending....");
      const formData = new FormData(event.target);
  
      formData.append("access_key", "33e08393-6f2c-4b51-9c02-6b9570a657d1");
  
      const response = await fetch("https://api.web3forms.com/submit", {
        method: "POST",
        body: formData
      });
  
      const data = await response.json();
  
      if (data.success) {
        setResult("Form Submitted Successfully");
        event.target.reset();
      } else {
        console.log("Error", data);
        setResult(data.message);
      }
    };

    return (
    <>
    <PageTitle title="Ham.Zone Contact" />
    <div className='hz-mtn'>
        <section className='hz-mtn'>
            <p>
                <h1>Contact Us</h1> 
            </p>
            <span>{result}</span>
            <form onSubmit={onSubmit}>
                <div className="input-box">
                    <label>Your Name</label>
                    <input type="text" className="field" placeholder='Name' name='name' required/>
                </div>
                <div className="input-box">
                    <label>Your E-Mail Address</label>
                    <input type="text" className="field" placeholder='E-Mail' name='email' required/>
                </div>
                <div className="input-box">
                    <label>Your Name</label>
                    <textarea className="field mess" name='message' id="" placeholder='Enter your message here' required></textarea>
                </div>
                <button type="submit">Send Your Message</button>
            </form>

        </section>
    </div>
    <div>
    <Footer/>
    </div>
    </>
    );
}

export default Contact;